//@ts-nocheck
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
export const configJSON = require("../config");
import CloseIcon from "@material-ui/icons/Close";
import UploadBox from "../UploadBoxRoadmap.web";
import { hexMask } from "../masks";
import CustomPackagInRoadMap from "../CustomPackagInRoadMap.web";
import { intitalQuestion } from "../../../../components/src/PackageList.web";
import { resetFormState } from "../FirmFinancialsNew.web";

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: any;
  accountId: any;
  activeStep: number;
  selectedPackageList: [];
  selectedCustomPaclage: [];
  customPackages: [];
  selectPackageIndex: number;
  roadMapInfo: any;
  isSidebarVisible: boolean;
  selectedRoadMap: any;
  loading: boolean;
  mobileOpen: boolean;
  packageOverviewSaveAndContinue: boolean;
  chipData: { key: string; label: string; isFromQuestionFilter?: boolean }[];
  allFeatureList: any[];
  selectedFeatureList: any[];
  filterQuestionList: any[];
  currentQuestionIndex: number;
  selectedPackageDetail: null | unknown;
  packageList: any[];
  defaultPackageTemplate: any;
  name: string;
  description: string;
  package_detail_link: string;
  stratagic_rate: number | "";
  value_margin: number | "";
  contract_period: number | "";
  totalOneTimeFess: number;
  totalRecurringFess: number;
  bumpList: any[];
  is_unfront_payment: boolean;
  unfront_payment_type: "percentage" | "lumpsump";
  unfront_percentage: number;
  unfront_lump_suump_amount: number;
  totalContractValue: number;
  totalContractValueAfterRemovingUnfrontAmount: number;
  custom_package_features: any[];
  totalAvailalbeFeatures: number;
  selectedCategory: any;
  selectedSearchField: any;
  currentPackageCustomizationIndex: number;
  selectedPaymentTerms: any[];
  isCustomPackageTemplate: boolean;
  duplicatepkgdetails: null | unknown
  selectedDuplicate: boolean;
  allFeatureListForPricingScreen: any;
  packageListForDropDown: any;
  isCreateFromDefaultTemplate: boolean;
  default_firm_finance_strategic_price: any;
  default_strategic_price: any;
  // client info state
  clientInformationId: any;
  clientInformation: any;
  isClientAligned: any;
  scheduleDateTime: any;
  quizId: null | string | number;
  q1: any;
  q2: any;
  q3: any;
  q4: any;
  q5: any;
  q6: any;
  // PackageList State
  recommendedPackages: any;
  selectedCurrentPackage: any;
  isErrorPresent: any;
  roadMapName: string;
  logoAsFile: any;
  logo: any;
  email: any;
  firm_name: any;
  phone_number: any;
  primaryColor: any;
  secondaryColor: any;
  web_url: any;
  brandingId: any;
  //firmfinancial state
  isCalculated: boolean;
  currentAnnualRev: number;
  annualRevGoal: number;
  businessClients: string;
  individualClients: string;
  totalClients: number;
  firmFinanceId: number;
  expandLabors: boolean;

  operatingSpaceExpense: any;
  technologyExpense: any;
  bizdevMarketingExpense: any;
  insuranceExpense: any;
  othersExpense: any;
  laborTotalExpense: any;
  totalExpenses: any;
  isCreateDuplicatePackage : boolean;
  expenses: any;
  expenseLaborsAttributes: any;
  pricingIdObject: object;
  pricingData: any;

  businessRevenue: any;
  individualRevenue: any;
  totalRevenue: any;
  totalArr: any;
  totalMrr: any;

  formValues: any;
  currentAnnualGross: number;
  currentMonthlyGross: number;
  goalAnnualGross: number;
  goalMonthlyGross: number;
  currentAnnualOwner: number;
  currentMonthlyOwner: number;
  goalAnnualOwner: number;
  goalMonthlyOwner: number;
  percentOfClientsNewBusinessIncubator: number;
  revGoalNewBusinessIncubator: number;
  percentOfClientsBusinessBundle: number;
  revGoalBusinessBundle: number;
  percentOfClientsBusinessTax: number;
  revGoalBusinessTax: number;
  percentOfClientsAccountingSuccess: number;
  revGoalAccountingSuccess: number;
  percentOfClientsPayrollSuccess: number;
  revGoalPayrollSuccess: number;
  percentOfClientsPersonalBundle: number;
  revGoalPersonalBundle: number;
  percentOfClientsIRSRapidRelief: number;
  revGoalIRSRapidRelief: number;
  percentOfClientsReportingOnlyBusiness: number;
  revGoalReportingOnlyBusiness: number;
  percentOfClientsReportingOnlyIndividual: number;
  revGoalReportingOnlyIndividual: number;
  calculationsList: any;
  rowForCllient: [];
  confidential_statement: string;
  searchedClientsList: any;
  roadmap_client_link: string;
  handleCondition : boolean;
  searchfeild: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class StartRoadMapController extends CustomPackagInRoadMap<
  Props,
  S,
  SS
> {
  getRoadMapAPiCallId: string = "";
  deletePastRoadMapAPiCallId: string = "";
  deletePaymentTermCallId: string = "";
  createAndUpdateClientInformationApiCallId: string = "";
  getCustomPackageAPiCallId: string = "";
  deletePackagefromRoadmapAPiCallId: string = "";
  submitQuizApiCallId: string = "";
  updateRoadmapWithNameApiCallId: string = "";
  updateRoadmapApiCallId: string = "";
  getBrandingApiCallId: string = "";
  createAndUpdateBrandingApiCallId: string = "";
  createFirmFinancialsApiCallId: string = "";
  getDefaultFirmFinancialsByAccountAPiCallId: string = "";
  orderOfPackageId: string = "";
  searchClientApiCallId: string = "";
  addCustomFeatureInDbCallId: string ="";

  nf: any = new Intl.NumberFormat("en-US");

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: null,
      accountId: "",
      activeStep: 0,
      allFeatureListForPricingScreen: [],
      selectedPaymentTerms: [],
      selectedPackageList: [],
      selectedCustomPaclage: [],
      customPackages: [],
      selectPackageIndex: 0,
      roadMapInfo: null,
      isSidebarVisible: true,
      selectedRoadMap: {},
      loading: true,
      mobileOpen: false,
      packageOverviewSaveAndContinue: false,
      selectedCategory: [],
      chipData: [],
      packageList: [],
      selectedSearchField: "",
      selectedFeatureList: [],
      allFeatureList: [],
      isCreateFromDefaultTemplate: false,
      defaultPackageTemplate: "select",
      filterQuestionList: intitalQuestion,
      currentQuestionIndex: 0,
      selectedPackageDetail: {},
      duplicatepkgdetails : {},
      selectedDuplicate : false,
      name: "Custom Package",
      description: "",
      package_detail_link: "",
      stratagic_rate: "",
      value_margin: "",
      contract_period: "",
      totalOneTimeFess: 0,
      totalRecurringFess: 0,
      is_unfront_payment: false,
      unfront_payment_type: "percentage",
      unfront_percentage: 0,
      unfront_lump_suump_amount: 0,
      bumpList: [],
      totalContractValue: 0,
      totalContractValueAfterRemovingUnfrontAmount: 0,
      custom_package_features: [],
      totalAvailalbeFeatures: 0,
      currentPackageCustomizationIndex: 0,
      isCustomPackageTemplate: false,
      packageListForDropDown: [],
      default_firm_finance_strategic_price: null,
      default_strategic_price: null,
      // ClientInfo state
      clientInformationId: null,
      clientInformation: {},
      isClientAligned: "",
      scheduleDateTime: "",
      quizId: null,
      q1: {
        tag: "Business",
        value: "",
      },
      q2: {
        tag: "Accounting",
        value: "",
      },
      q3: {
        tag: "Payroll",
        value: "",
      },
      q4: {
        tag: "New Business",
        value: "",
      },
      q5: {
        tag: "IRS",
        value: "",
      },
      q6: {
        tag: "Advisory",
        value: "",
      },
      // PackageList state
      recommendedPackages: [],
      selectedCurrentPackage: null,
      isErrorPresent: false,
      roadMapName: "",
      logoAsFile: null,
      logo: null,
      email: "",
      firm_name: "",
      phone_number: "",
      primaryColor: "#54A4A6",
      secondaryColor: "#eb1a6b",
      web_url: "",
      brandingId: null,
      // firm financials state
      isCalculated: false,
      ...resetFormState,
      percentOfClientsNewBusinessIncubator: "8.00",
      revGoalNewBusinessIncubator: "10",
      percentOfClientsBusinessBundle: "33.00",
      revGoalBusinessBundle: "30",
      percentOfClientsBusinessTax: "20.00",
      revGoalBusinessTax: "10",
      percentOfClientsAccountingSuccess: "10.00",
      revGoalAccountingSuccess: "10",
      percentOfClientsPayrollSuccess: "1.00",
      revGoalPayrollSuccess: "5",
      percentOfClientsPersonalBundle: "45.00",
      revGoalPersonalBundle: "12",
      percentOfClientsIRSRapidRelief: "5.00",
      revGoalIRSRapidRelief: "5",
      percentOfClientsReportingOnlyBusiness: "28.00",
      revGoalReportingOnlyBusiness: "8",
      percentOfClientsReportingOnlyIndividual: "50.00",
      revGoalReportingOnlyIndividual: "10",
      firmFinanceId: "",
      expandLabors: false,
      pricingIdObject: {},
      formValues: {},
      calculationsList: "",
      rowForCllient: [],
      confidential_statement: "",
      searchedClientsList: [],
      roadmap_client_link: "",
      isCreateDuplicatePackage : false,
      searchfeild: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  toDataURL = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = this.loadednd.bind(this, reader, resolve);
    });
  };
  loadednd = (reader, resolve) => {
    const base64data = reader.result;
    resolve(base64data);
  };

  dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getRoadMapAPiCallId:
          this.handleRoadMapResponse(responseJson);
          break;
        case this.deletePastRoadMapAPiCallId:
          this.handleDeleteRoadMapResponse(responseJson);
          break;
        case this.deletePackagefromRoadmapAPiCallId:
          this.handleDeletePackageFromRoadMap(responseJson);
          break;
        case this.getAllFeaturesCallId:
          this.handleAllFeatureList(responseJson);
          break;
        case this.getAllFeaturesofSelectedPackageCallId:
          this.handleSelectedPackageFeatureList(responseJson);
          break;
        case this.getBumpsApiCallId:
          this.handleGetBumpResponse(responseJson);
          break;
        case this.createCustomPackageCallId:
          this.savePackageToRoadMapResponse(responseJson);
          break;
        case this.deletePaymentTermCallId:
          this.handleDeletePaymentTermResponse(responseJson);
          break;
        case this.getAllFeaturesForPriceingScreenCallId:
          this.handleAllFeatureListForPricing(responseJson);
          break;
        case this.getPackagesApiCallId:
          this.handleGetPackagesResponse(responseJson);
          break;
        case this.createAndUpdateClientInformationApiCallId:
          this.handleCreateAndUpdateClientInformationResponse(responseJson);
          break;
        case this.orderOfPackageId:
          this.handleUpdatePackage(responseJson);
          break;
        case this.submitQuizApiCallId:
          this.handleSubmitQuizResponse(responseJson);
          break;
        case this.getCustomPackageAPiCallId:
          this.handleCustomPackageResponse(responseJson);
          break;
        case this.updateRoadmapWithNameApiCallId:
          this.handleUpdateRoadmapWithNameResponse(responseJson);
          break;
        case this.updateRoadmapApiCallId:
          this.updateRoadmapResponse(responseJson);
          break;
        case this.getBrandingApiCallId:
          this.handleGetBrandingResponse(responseJson);
          break;
        case this.createAndUpdateBrandingApiCallId:
          this.handleCreateAndUpdateBrandingResponse(responseJson);
          break;
        case this.createFirmFinancialsApiCallId:
          this.handleCreateFirmFinancialsResponse(responseJson);
          break;
        case this.getDefaultFirmFinancialsByAccountAPiCallId:
          this.handleGetDefaultFirmFinancialsByAccountResponse(responseJson);
          break;
        case this.getGSuiteDataCallId:
          this.handleGsuiteData(responseJson);
          break; 
        case this.searchClientApiCallId:
          this.handleSearchClientApiResponse(responseJson);
          break; 
        case this.addCustomFeatureInDbCallId2:
          this.props.showToast({
            message: "Feature added Succesfully",
            type: "success",
          });
        break; 
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (
        AlertBodyMessage === "Session TimeOut,Please Login Again." ||
        AlertBodyMessage === "User Account Not Found"
      ) {
        await StorageProvider.remove("authToken");
        this.props.history.push("/");
      }
    }
  }
  handleGsuiteData = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      this.setState({
        googleObj: responseJson?.data?.attributes?.gsuite_data,
        googleId: responseJson?.data.id,
      });
    }
  };

  formatLaborsAttributes = (laborsAttributes) => {
    return laborsAttributes.data
      .sort((a, b) => a.id - b.id)
      .map((singleLabor, index) => {
        let obj = {};
        obj = {
          id: singleLabor?.attributes.id,
          labor_role:
            singleLabor?.attributes.labor_role ||
            `Additional Labor ${index - 2}`,
          current_annual: singleLabor?.attributes.current_annual,
          unit: singleLabor?.attributes.unit || 1,
          employee_compensation: singleLabor?.attributes.employee_compensation,
          firm_finance_id: singleLabor?.attributes.firm_finance_id,
          _destroy: 0,
        };
        return obj;
      });
  };

  formatPricingData = (responseJson) => {
    if (responseJson.pricing_value.data.length > 0) {
      let pricingIdObject = {};
      const pricingData = responseJson.pricing_value.data
        .sort((a, b) => a.id - b.id)
        .map((pricingItem, index) => {
          pricingIdObject[
            `${pricingItem?.attributes?.package_name}${pricingItem?.attributes?.package_type}`
          ] = pricingItem?.attributes?.id;
          return {
            id: pricingItem.attributes.id,
            package_name: pricingItem.attributes.package_name,
            package_type: pricingItem.attributes.package_type,
            time_period: pricingItem.attributes.time_period,
            no_of_packages: pricingItem.attributes.no_of_packages,
            rev_goal_percent: pricingItem.attributes.rev_goal_percent,
            percent_of_client: pricingItem.attributes.percent_of_client,
            strategic_price: pricingItem.attributes.strategic_price,
            revenue_map: pricingItem.attributes.revenue_map,
          };
        });
      this.setState({
        pricingData: pricingData || [],
        pricingIdObject,
      });
    }
  };

  setStateForOtherData = (responseJson) => {
    const laborsAttributes = this.formatLaborsAttributes(
      responseJson?.firm_labor_expenses
    );
    this.setState({
      //goals
      currentAnnualGross: this.nf.format(
        responseJson?.gross_revenue_current_annual || ""
      ),
      goalAnnualGross: this.nf.format(
        responseJson?.gross_revenue_goal_annual || ""
      ),
      currentAnnualOwner: this.nf.format(
        responseJson?.owner_compensation_current_annual || ""
      ),
      goalAnnualOwner: this.nf.format(
        responseJson?.owner_compensation_goal_annual || ""
      ),
      //client info values
      businessClients: responseJson.business_client_info,
      individualClients: responseJson.individual_client_info,
      totalClients: responseJson.total_client_info,
      //expenses
      operatingSpaceExpense: this.nf.format(
        responseJson?.operating_space_expense || ""
      ),
      technologyExpense: this.nf.format(responseJson?.technology_expense || ""),
      bizdevMarketingExpense: this.nf.format(
        responseJson?.bizdev_marketing_expense || ""
      ),
      insuranceExpense: this.nf.format(responseJson?.insurance_expense || ""),
      othersExpense: this.nf.format(responseJson?.others_expense || ""),
      //labor expense
      expenseLaborsAttributes: [...laborsAttributes],
      //expense totals
      laborTotalExpense: responseJson.labor_total_expense,
      totalExpenses: this.nf.format(responseJson.total_expenses),
      calculationsList: {
        annual_free_cashflow: responseJson.annual_free_cashflow,
        gross_profit_margin: responseJson.gross_profit_margin,
        monthly_revenue_floor: responseJson.monthly_revenue_floor,
      },
      businessRevenue: responseJson.business_revenue,
      individualRevenue: responseJson.individual_revenue,
      totalRevenue: responseJson.total_revenue,
      totalArr: responseJson.total_arr,
      totalMrr: responseJson.total_mrr,
    });
  };

  handleCreateFirmFinancialsResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      this.setState(
        {
          isCalculated: true,
          firmFinanceId: responseJson?.data.id,
        },
        () => {
          this.setStateForOtherData(responseJson.data.attributes);
          this.formatPricingData(responseJson.data.attributes);
        }
      );
    } else if (
      responseJson?.message &&
      responseJson?.message === "AccessDenied for current user"
    ) {
      this.props.showToast({
        type: "error",
        message: responseJson?.message,
      });
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  setPercentAndRevenue = (responseJson) => {
    if (responseJson?.pricing_value.data.length > 0) {
      responseJson?.pricing_value.data.forEach((pricingDataItem) => {
        switch (pricingDataItem?.attributes?.package_name) {
          case "New Business Incubator":
            {
              this.setState({
                percentOfClientsNewBusinessIncubator:
                  pricingDataItem?.attributes?.percent_of_client,
                revGoalNewBusinessIncubator:
                  pricingDataItem?.attributes?.rev_goal_percent,
              });
            }
            break;
          case "Business Bundle":
            {
              this.setState({
                percentOfClientsBusinessBundle:
                  pricingDataItem?.attributes?.percent_of_client,
                revGoalBusinessBundle:
                  pricingDataItem?.attributes?.rev_goal_percent,
              });
            }
            break;
          case "Business Tax & Advisory":
            {
              this.setState({
                percentOfClientsBusinessTax:
                  pricingDataItem?.attributes?.percent_of_client,
                revGoalBusinessTax:
                  pricingDataItem?.attributes?.rev_goal_percent,
              });
            }
            break;
          case "Accounting Success":
            {
              this.setState({
                percentOfClientsAccountingSuccess:
                  pricingDataItem?.attributes?.percent_of_client,
                revGoalAccountingSuccess:
                  pricingDataItem?.attributes?.rev_goal_percent,
              });
            }
            break;
          case "Payroll Success":
            {
              this.setState({
                percentOfClientsPayrollSuccess:
                  pricingDataItem?.attributes?.percent_of_client,
                revGoalPayrollSuccess:
                  pricingDataItem?.attributes?.rev_goal_percent,
              });
            }
            break;
          case "Personal Bundle":
            {
              this.setState({
                percentOfClientsPersonalBundle:
                  pricingDataItem?.attributes?.percent_of_client,
                revGoalPersonalBundle:
                  pricingDataItem?.attributes?.rev_goal_percent,
              });
            }
            break;
          case "IRS Rapid Relief":
            {
              this.setState({
                percentOfClientsIRSRapidRelief:
                  pricingDataItem?.attributes?.percent_of_client,
                revGoalIRSRapidRelief:
                  pricingDataItem?.attributes?.rev_goal_percent,
              });
            }
            break;
        }
        if (
          pricingDataItem?.attributes?.package_name ===
            "Reporting-Only Package" &&
          pricingDataItem?.attributes?.package_type === "business"
        ) {
          this.setState({
            percentOfClientsReportingOnlyBusiness:
              pricingDataItem?.attributes?.percent_of_client,
            revGoalReportingOnlyBusiness:
              pricingDataItem?.attributes?.rev_goal_percent,
          });
        }
        if (
          pricingDataItem?.attributes?.package_name ===
            "Reporting-Only Package" &&
          pricingDataItem?.attributes?.package_type === "Individual"
        ) {
          this.setState({
            percentOfClientsReportingOnlyIndividual:
              pricingDataItem?.attributes?.percent_of_client,
            revGoalReportingOnlyIndividual:
              pricingDataItem?.attributes?.rev_goal_percent,
          });
        }
      });
    }
  };

  handleGetRoadMapResponseForFirmFinancialsPage = (responseJson) => {
    if (responseJson?.data?.attributes?.firm_finance?.data?.id) {
      if (
        responseJson?.data.attributes.firm_finance.data.attributes
          .firm_labor_expenses?.data.length > 0
      ) {
        this.setState(
          {
            roadMapInfo: responseJson.data,
            isCalculated: true,
            firmFinanceId: responseJson?.data.attributes.firm_finance.data.id,
          },
          () => {
            this.setStateForOtherData(
              responseJson?.data.attributes.firm_finance?.data?.attributes
            );
            this.formatPricingData(
              responseJson?.data.attributes.firm_finance?.data?.attributes
            );
            this.setPercentAndRevenue(
              responseJson?.data.attributes.firm_finance?.data?.attributes
            );
          }
        );
      }
    } else {
      this.getDefaultFirmFinancialsByAccount();
    }
  };

  handleGetDefaultFirmFinancialsByAccountResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes?.default_firm_finance?.data?.id) {
      if (
        responseJson?.data?.attributes?.default_firm_finance?.data?.attributes
          ?.firm_labor_expenses?.data.length > 0
      ) {
        this.setState(
          {
            isCalculated: true,
          },
          () => {
            this.setStateForOtherData(
              responseJson?.data?.attributes?.default_firm_finance?.data
                ?.attributes
            );
            this.formatPricingData(
              responseJson?.data?.attributes?.default_firm_finance?.data
                ?.attributes
            );
            this.setPercentAndRevenue(
              responseJson?.data?.attributes?.default_firm_finance?.data
                ?.attributes
            );
          }
        );
      }
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1,
      isSidebarVisible: true,
      isEditRoadMapPackageTemplate: false,
    });
  };

  updateRoadmapResponse = async (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      await StorageProvider.set(
        "primaryColor",
        responseJson?.data?.attributes?.add_primary_color
      );
      await StorageProvider.set(
        "secondaryColor",
        responseJson?.data?.attributes?.add_secondary_color
      );
      this.handleNext();
    } else {
      this.handleApiError(responseJson);
    }
  };

  setBrandingState = (responseJson, fileData = null) => {
    const attributes =
      responseJson && responseJson.data && responseJson.data.attributes;
    this.setState({
      email: attributes && attributes.email,
      firm_name: attributes && attributes.firm_name,
      logo: (attributes && attributes.logo) || null,
      phone_number: attributes && attributes.phone_number,
      primaryColor: (attributes && attributes.primary_color) || "#54A4A6",
      secondaryColor: (attributes && attributes.secondary_color) || "#eb1a6b",
      web_url: (attributes && attributes.web_url) || "",
      logoAsFile: fileData,
      brandingId: attributes && attributes.id,
    });
  };

  handleGetBrandingResponse = async (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes) {
      if (responseJson.data.attributes.logo) {
        this.toDataURL(responseJson.data.attributes.logo).then(
          async (dataUrl) => {
            const fileData = await this.dataUrlToFile(dataUrl, "imageName.jpg");
            this.setBrandingState(responseJson, fileData);
          }
        );
      } else {
        this.setBrandingState(responseJson);
      }
    } else {
      this.handleApiError(responseJson);
    }
  };

  handleCreateAndUpdateBrandingResponse = async (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes) {
      if (responseJson.data.attributes.logo) {
        this.toDataURL(responseJson.data.attributes.logo).then(
          async (dataUrl) => {
            const fileData = await this.dataUrlToFile(dataUrl, "imageName.jpg");
            this.setBrandingState(responseJson, fileData);
          }
        );
      } else {
        this.setBrandingState(responseJson);
      }
      this.props.showToast({
        type: "success",
        message: "Changes saved successfully.",
      });
    } else {
      this.handleApiError(responseJson);
    }
  };

  setRoamMapComplete = () => {
    this.setState({ isRoadMapSaved: true }, () => {
      this.props.showToast({
        type: "success",
        message: "RoadMap Created Succssfully",
      });
      this.props.history.push("/home/roadmaps/pastRoadmaps");
    });
  };

  handleUpdateRoadmapWithNameResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      this.setRoamMapComplete();
    } else {
      this.handleApiError(responseJson);
    }
  };

  handleDeleteRoadMapResponse = (responseJson) => {
    this.props.hideLoader();
  };

  handleCustomPackageResponse = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setState({
        customPackages: responseJson?.data.filter(
          (item) => !item.attributes.road_customization_id
        ),
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  sortPackage = (a, b) => {
    if (
      new Date(a?.attributes.updated_at).getTime() <
      new Date(b?.attributes.updated_at).getTime()
    ) {
      return -1;
    }
    if (
      new Date(a?.attributes.updated_at).getTime() >
      new Date(b?.attributes.updated_at).getTime()
    ) {
      return 1;
    }
    return 0;
  };

  handleRoadMapResponseForRoadMapPage = (responseJson) => {
    if (responseJson?.data?.attributes?.add_logo) {
      let url = responseJson?.data?.attributes?.add_logo;

      this.toDataURL(url).then(async (dataUrl) => {
        const fileData = await this.dataUrlToFile(dataUrl, "imageName.jpg");
        this.setState({
          logoAsFile: fileData,
          logo: responseJson?.data?.attributes?.add_logo,
          roadMapInfo: responseJson?.data,
          customPackages:
            responseJson?.data?.attributes?.custom_packages?.data.sort(
              this.sortPackage.bind(this)
            ) || [],
          roadMapName: responseJson?.data?.attributes?.name || "",
        });
      });
    }
  };

  handleRoadMapResponseForRoadmapCustomizationPage = async (responseJson) => {
    const attributes =
      responseJson && responseJson.data && responseJson.data.attributes;

    if (
      attributes &&
      (attributes.add_primary_color ||
        attributes.add_secondary_color ||
        attributes.phone_number ||
        attributes.web_url ||
        attributes.email ||
        attributes.firm_name ||
        attributes.add_logo)
    ) {
      let fileData = null;
      if(attributes.add_logo) {
        const dataUrl = await this.toDataURL(attributes.add_logo);
        fileData = await this.dataUrlToFile(dataUrl, "imageName.jpg");
      }
      this.setState({
        email: attributes.email || "",
        firm_name: attributes.firm_name || "",
        phone_number: attributes.phone_number || "",
        primaryColor: attributes.add_primary_color || "#54A4A6",
        secondaryColor: attributes.add_secondary_color || "#eb1a6b",
        web_url: attributes.web_url || "",
        logo: attributes.add_logo || null,
        logoAsFile: fileData,
      });
    } else {
      this.getBranding();
    }
  };

  handleGetPackagesResponseForPackageListPage = (responseJson) => {
    const oneLinerDescriptions = {
      "New Business Incubator": "New Business Startup Package.",
      "Business Bundle":
        "Strategic Advisory, Tax Prep, Accounting, and/or Payroll, all in one package.",
      "Business Tax & Advisory":
        "Strategic Advisory & Tax Prep (without accounting or payroll).",
      "Accounting Success Package": "Strategic Accounting Package.",
      "Payroll Success Package": "Help with Strategic Payroll & HR.",
      "Personal Bundle":
        "Strategic Advisory & Tax Prep for Individual Clients (non-business owners).",
      "IRS Rapid Relief Package": "IRS Resolution Package.",
      "Reporting-Only Package (Individual)":
        "Tax Preparation & Historic Reporting for an Individual Client (non-business owner).",
      "Reporting-Only Package (Business)":
        "Tax Preparation & Historic Reporting for a Business Client.",
    };

    let selectedPackages = responseJson?.data.map((item) => {
      const oneLinerDescription =
        oneLinerDescriptions[item?.attributes?.name] || "";
      return {
        ...item,
        one_liner_description: oneLinerDescription,
      };
    });

    const selectedCustomPackageWithId = this.state.selectedPackageList
      .filter(
        (pkg: any) =>
          pkg.type === "custom_package" && pkg.attributes.package_management_id
      )
      .map((test) => ({ ...test, notIncludeInRoadmap: true }));

    const selectedCustomPackages = this.state.selectedPackageList
      .filter((pkg: any) => pkg.type === "custom_package")
      .map((test) => ({ ...test, notIncludeInRoadmap: true }));

    this.setState({
      packageList: selectedPackages,
      selectedPackageList: selectedCustomPackageWithId,
      selectedCustomPaclage: selectedCustomPackages,
    });
    this.getCustomPackage();
    this.getRoadMap(this.state.roadMapInfo.id);
  };
  handleGetPackagesResponse = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.handleGetPackagesResponseForPackageListPage(responseJson);
      this.setState({
        packageListForDropDown: responseJson?.data,
      });
    } else {
      this.handleApiError(responseJson);
    }
  };

  saveStateDataForClientInfo = (responseJson) => {
    if (responseJson?.data?.id && responseJson?.data?.attributes) {
      this.setState({
        clientInformation:
          responseJson?.data?.attributes?.client_information?.data,
        isClientAligned:
          responseJson?.data?.attributes?.client_information?.data?.attributes
            ?.client_discovery?.isClientAligned,
        scheduleDateTime:
          responseJson?.data?.attributes?.client_information?.data?.attributes
            ?.client_discovery?.scheduleDateTime,
        clientInformationId:
          responseJson?.data?.attributes?.client_information?.data?.id,
        quizId: responseJson?.data?.attributes?.quiz?.data?.id,
        q1: {
          tag: "Business",
          value:
            responseJson?.data?.attributes?.quiz?.data?.attributes?.quiz
              ?.quizQuestion1?.value,
        },
        q2: {
          tag: "Accounting",
          value:
            responseJson?.data?.attributes?.quiz?.data?.attributes?.quiz
              ?.quizQuestion2?.value,
        },
        q3: {
          tag: "Payroll",
          value:
            responseJson?.data?.attributes?.quiz?.data?.attributes?.quiz
              ?.quizQuestion3?.value,
        },
        q4: {
          tag: "New Business",
          value:
            responseJson?.data?.attributes?.quiz?.data?.attributes?.quiz
              ?.quizQuestion4?.value,
        },
        q5: {
          tag: "IRS",
          value:
            responseJson?.data?.attributes?.quiz?.data?.attributes?.quiz
              ?.quizQuestion5?.value,
        },
        q6: {
          tag: "Advisory",
          value:
            responseJson?.data?.attributes?.quiz?.data?.attributes?.quiz
              ?.quizQuestion6?.value,
        },
      });
      let quiz = responseJson?.data?.attributes?.quiz?.data?.attributes?.quiz;
      if (
        quiz?.quizQuestion1?.value === "Yes" ||
        quiz?.quizQuestion1?.value === "No"
      ) {
        this.setPackageListWithQuizData(quiz);
      }
    }
  };

  getCaseOneData = (tagsList, selectedPackages) => {
    const packagesToCheck = [
      "Business",
      "Accounting",
      "Payroll",
      "New Business",
      "IRS",
      "Advisory",
    ];

    const packagesToPush = [
      ["Reporting-Only Package (Business)"],
      ["Accounting Success Package"],
      ["Payroll Success Package"],
      ["New Business Incubator"],
      ["IRS Rapid Relief Package"],
      ["Personal Bundle", "Business Tax & Advisory"],
    ];

    for (let i = 0; i < packagesToCheck.length; i++) {
      if (tagsList.every((tag) => packagesToCheck[i].includes(tag))) {
        selectedPackages.push(...packagesToPush[i]);
      }
    }
  };

  getCaseTwoData = (tagsList, selectedPackages) => {
    const packagesToCheck = [
      ["Advisory", "New Business"],
      ["Advisory", "IRS"],
      ["New Business", "IRS"],
      ["Business", "Accounting"],
      ["Business", "Payroll"],
      ["Business", "New Business"],
      ["Business", "IRS"],
      ["Business", "Advisory"],
    ];

    const packagesToPush = [
      ["New Business Incubator", "Personal Bundle", "Business Tax & Advisory"],
      [
        "IRS Rapid Relief Package",
        "Personal Bundle",
        "Business Tax & Advisory",
      ],
      ["IRS Rapid Relief Package", "New Business Incubator"],
      ["Business Bundle"],
      ["Business Bundle"],
      ["Business Tax & Advisory", "New Business Incubator"],
      ["Business Tax & Advisory", "IRS Rapid Relief Package"],
      ["Business Tax & Advisory"],
    ];

    for (let i = 0; i < packagesToCheck.length; i++) {
      if (tagsList.every((tag) => packagesToCheck[i].includes(tag))) {
        selectedPackages.push(...packagesToPush[i]);
      }
    }
  };

  getCaseThreeData = (tagsList, selectedPackages) => {
    const packagesToCheck = [
      ["Business", "Accounting", "Payroll"],
      ["Business", "Accounting", "New Business"],
      ["Business", "Accounting", "IRS"],
      ["Business", "Accounting", "Advisory"],
      ["Business", "Payroll", "New Business"],
      ["Business", "Payroll", "IRS"],
      ["Business", "Payroll", "Advisory"],
      ["Business", "New Business", "IRS"],
      ["Business", "New Business", "Advisory"],
      ["Business", "IRS", "Advisory"],
      ["IRS", "New Business", "Advisory"],
    ];

    const packagesToPush = [
      ["Business Bundle"],
      ["Business Bundle", "New Business Incubator"],
      ["Business Bundle", "IRS Rapid Relief Package"],
      ["Business Bundle", "Business Tax & Advisory"],
      ["Business Bundle", "New Business Incubator"],
      ["Business Bundle", "IRS Rapid Relief Package"],
      ["Business Bundle"],
      [
        "Business Tax & Advisory",
        "New Business Incubator",
        "IRS Rapid Relief Package",
      ],
      [
        "Business Tax & Advisory",
        "New Business Incubator",
        "Reporting-Only Package (Business)",
      ],
      [
        "Business Tax & Advisory",
        "IRS Rapid Relief Package",
        "Reporting-Only Package (Business)",
      ],
      [
        "New Business Incubator",
        "IRS Rapid Relief Package",
        "Business Tax & Advisory",
      ],
    ];

    for (let i = 0; i < packagesToCheck.length; i++) {
      if (tagsList.every((tag) => packagesToCheck[i].includes(tag))) {
        selectedPackages.push(...packagesToPush[i]);
      }
    }
  };

  getCaseFourData = (tagsList, selectedPackages) => {
    const packagesToCheck = [
      ["Business", "Accounting", "Payroll", "New Business"],
      ["Business", "Accounting", "Payroll", "IRS"],
      ["Business", "Accounting", "Payroll", "Advisory"],
      ["Business", "Accounting", "New Business", "IRS"],
      ["Business", "Accounting", "New Business", "Advisory"],
      ["Business", "Accounting", "IRS", "Advisory"],
      ["Business", "Payroll", "New Business", "IRS"],
      ["Business", "Payroll", "New Business", "Advisory"],
      ["Business", "Payroll", "IRS", "Advisory"],
      ["Business", "New Business", "IRS", "Advisory"],
    ];
    const packagesToPush = [
      ["Business Bundle", "New Business Incubator"],
      ["Business Bundle", "IRS Rapid Relief Package"],
      ["Business Bundle"],
      ["Business Bundle", "New Business Incubator", "IRS Rapid Relief Package"],
      ["Business Bundle", "New Business Incubator"],
      ["Business Bundle", "IRS Rapid Relief Package"],
      ["Business Bundle", "New Business Incubator", "IRS Rapid Relief Package"],
      ["Business Bundle", "New Business Incubator"],
      ["Business Bundle", "IRS Rapid Relief Package"],
      [
        "Business Tax & Advisory",
        "New Business Incubator",
        "IRS Rapid Relief Package",
      ],
    ];

    for (let i = 0; i < packagesToCheck.length; i++) {
      if (packagesToCheck[i].every((tag) => tagsList.includes(tag))) {
        selectedPackages.push(...packagesToPush[i]);
      }
    }
  };

  getCaseFiveData = (tagsList, selectedPackages) => {
    const packagesToCheck = [
      ["Business", "Accounting", "Payroll", "New Business", "IRS"],
      ["Business", "Accounting", "Payroll", "New Business", "Advisory"],
      ["Business", "Accounting", "Payroll", "IRS", "Advisory"],
      ["Business", "Accounting", "New Business", "IRS", "Advisory"],
      ["Business", "Payroll", "New Business", "IRS", "Advisory"],
    ];

    const packagesToPush = [
      ["Business Bundle", "New Business Incubator", "IRS Rapid Relief Package"],
      ["Business Bundle", "New Business Incubator"],
      ["Business Bundle", "IRS Rapid Relief Package"],
      ["Business Bundle", "New Business Incubator", "IRS Rapid Relief Package"],
      ["Business Bundle", "New Business Incubator", "IRS Rapid Relief Package"],
    ];

    for (let i = 0; i < packagesToCheck.length; i++) {
      if (packagesToCheck[i].every((tag) => tagsList.includes(tag))) {
        selectedPackages.push(...packagesToPush[i]);
      }
    }
  };

  setPackageListWithQuizData = (quiz) => {
    this.setState({
      recommendedPackages: [],
    });

    let quizData = quiz;
    let tagsList = Object.values(quizData)
      .filter((data) => data.value === "Yes")
      .map((data) => data.tag);
    let selectedPackages = [];

    switch (tagsList.length) {
      case 0:
        {
          selectedPackages.push("Reporting-Only Package (Individual)");
        }
        break;
      case 1:
        {
          this.getCaseOneData(tagsList, selectedPackages);
        }
        break;
      case 2:
        {
          this.getCaseTwoData(tagsList, selectedPackages);
        }
        break;
      case 3:
        {
          this.getCaseThreeData(tagsList, selectedPackages);
        }
        break;
      case 4:
        {
          this.getCaseFourData(tagsList, selectedPackages);
        }
        break;
      case 5:
        {
          this.getCaseFiveData(tagsList, selectedPackages);
        }
        break;
      case 6:
        {
          selectedPackages.push("Business Bundle");
          selectedPackages.push("New Business Incubator");
          selectedPackages.push("IRS Rapid Relief Package");
        }
        break;
    }

    let selectedPackageListFromQuiz = [];
    this.state.packageList.forEach((singlePackage) => {
      if (selectedPackages.includes(singlePackage?.attributes?.name)) {
        selectedPackageListFromQuiz.push(singlePackage?.id);
      }
    });
    this.setState({
      recommendedPackages: selectedPackageListFromQuiz,
    });
  };

  setQuizAndClientInfoState = (responseJson) => {
    this.setState({
      clientInformation: responseJson?.data,
      isClientAligned:
        responseJson?.data?.attributes?.client_discovery?.isClientAligned,
      scheduleDateTime:
        responseJson?.data?.attributes?.client_discovery?.scheduleDateTime,
      clientInformationId: responseJson?.data?.id,
    });
  };

  handleCreateAndUpdateClientInformationResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setQuizAndClientInfoState(responseJson);
      this.handleNext();
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleSearchClientApiResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setState({
        searchedClientsList: responseJson.data,
      });
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleSubmitQuizResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setState({
        quizId: responseJson?.data?.id,
        q1: {
          tag: "Business",
          value: responseJson?.data?.attributes?.quiz?.quizQuestion1?.value,
        },
        q2: {
          tag: "Accounting",
          value: responseJson?.data?.attributes?.quiz?.quizQuestion2?.value,
        },
        q3: {
          tag: "Payroll",
          value: responseJson?.data?.attributes?.quiz?.quizQuestion3?.value,
        },
        q4: {
          tag: "New Business",
          value: responseJson?.data?.attributes?.quiz?.quizQuestion4?.value,
        },
        q5: {
          tag: "IRS",
          value: responseJson?.data?.attributes?.quiz?.quizQuestion5?.value,
        },
        q6: {
          tag: "Advisory",
          value: responseJson?.data?.attributes?.quiz?.quizQuestion6?.value,
        },
      });
      let quiz = responseJson?.data?.attributes?.quiz;
      this.setPackageListWithQuizData(quiz);
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleDeletePackageFromRoadMap = (responseJson: any) => {
    this.getRoadMap(this.state.roadMapInfo.id);
    this.props.hideLoader();
    if (responseJson.message === "Package Deleted Successfully") {
      this.props.showToast({
        message: responseJson.message,
        type: "success",
      });
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };
  getRoadmapLink = (responseJson) => {
    let roadmapLink =
      window.location.origin + `/roadmapPackageSelectionByClient?`;
    let params = "";
    if (this.state.accountId) {
      params += `?accountId=${this.state.accountId}`;
    }
    if (responseJson?.data?.attributes?.client_information?.data?.id) {
      const clientId =
        responseJson.data.attributes.client_information.data.id;
      params += `&clientId=${clientId}`;
    }
    if (responseJson.data.id) {
      const roadmap_id = responseJson.data.id;
      params += `&roadmapId=${roadmap_id}`;
    }
    if (params) {
      roadmapLink += `roadmapId=${btoa(params)}`;
    }
    return roadmapLink;
  };

  handleRoadMapResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      switch (this.state.activeStep) {
        case 0:
          this.handleGetRoadMapResponseForFirmFinancialsPage(responseJson);
          break;
        case 1:
        case 2:
          this.saveStateDataForClientInfo(responseJson);
          break;
        case 4:
          this.handleRoadMapResponseForRoadmapCustomizationPage(responseJson);
          break;
        case 5:
          this.handleRoadMapResponseForRoadMapPage(responseJson);
          break;
        default:
          break;
      }
      const roadmapLink = this.getRoadmapLink(responseJson);  
      this.setState(
        {
          selectedPackageList: responseJson?.data?.attributes?.custom_packages?.data?.reverse(),
          selectedRoadMap: responseJson?.data?.attributes,
          roadMapInfo: responseJson?.data,
          confidential_statement:
            responseJson.data.attributes?.confidential_statement || "",
          roadMapName: responseJson?.data?.attributes?.name || "",
          roadmap_client_link: roadmapLink,
        },
        () => {
          const totalPackageWithPackageTerms = [];
          for (const pkg of this.state.selectedPackageList) {
            let paymentTemrs1 = this.processPaymentTerms({ data: pkg });
            paymentTemrs1.forEach((paymentterm: any) => {
              if (!paymentterm?.id) {
                totalPackageWithPackageTerms.push({
                  ...pkg,
                  attributes: {
                    ...pkg.attributes,
                    selectedPaymentTerm: {
                      ...paymentterm,
                      id: pkg?.id,
                    },
                  },
                });
              }
            });
          }
          this.setState({
            selectedPaymentTerms: totalPackageWithPackageTerms,
          });
        }
      );
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  handleDeletePaymentTermResponse = (responseJSON: any) => {
    this.props.hideLoader();
    if (responseJSON?.message?.length > 0) {
      this.getRoadMap(this.state.roadMapInfo?.id);
      this.setState({
        activeStep: 2,
      });
      this.props.showToast({
        type: "success",
        message: "payment term deleted successfully",
      });
    }
  };
  handleUpdatePackage = (responseJSON: any) => {
    this.props.hideLoader();
  };

  getRoadMap = () => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRoadMapAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/road_customizations/${this.state.roadMapInfo?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateRoadmapWithName = () => {
    this.props.showLoader();

    const headers = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateRoadmapWithNameApiCallId = requestMessage.messageId;

    let formData = new FormData();

    if (this.state.roadMapInfo?.attributes?.add_logo) {
      formData.append("add_logo", this.state.logoAsFile);
    }

    formData.append("name", this.state.roadMapName);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.roadmapEndpoint}/${this.state.roadMapInfo?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteRoadMap = (id: any) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePastRoadMapAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/road_customizations/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deletePackagefromRoadmap = (id: any) => {
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePackagefromRoadmapAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contentmanagement/custom_packages/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  savePackageToRoadMapResponse = (values?: any) => {
    this.props.hideLoader();
    if (values?.data?.id) {
      if (this.state.isCreateCustomPackage) {
        this.setState(
          {
            activeStep: 2,
            isEditRoadMapPackageTemplate: false,
            defaultPackageTemplate: null,
            isCreateCustomPackage: false,
            selectedCurrentPackage: null,
            selectedPackageList: [],
          },
          () => {
            this.resetPakcageCustomizationStep();
            this.props.showToast({
              type: "success",
              message: "Custom package created successfully",
            });
          }
        );
      } else {
        if (this.state.isEditRoadMapPackageTemplate) {
          this.setState(
            {
              activeStep: 2,
              isEditRoadMapPackageTemplate: false,
              defaultPackageTemplate: null,
              selectedCurrentPackage: null,
            },
            () => {
              this.resetPakcageCustomizationStep();
              this.getRoadMap(this.state.roadMapInfo?.id);
            }
          );
        } else {
          this.getData(values);
        }
      }
    } else {
      this.handleApiError(values);
    }
  };

  getQuizObject = () => {
    return {
      quizQuestion1: {
        tag: this.state.q1?.tag,
        value: this.state.q1?.value,
      },
      quizQuestion2: {
        tag: this.state.q2?.tag,
        value: this.state.q2?.value,
      },
      quizQuestion3: {
        tag: this.state.q3?.tag,
        value: this.state.q3?.value,
      },
      quizQuestion4: {
        tag: this.state.q4?.tag,
        value: this.state.q4?.value,
      },
      quizQuestion5: {
        tag: this.state.q5?.tag,
        value: this.state.q5?.value,
      },
      quizQuestion6: {
        tag: this.state.q6?.tag,
        value: this.state.q6?.value,
      },
    };
  };

  createAndUpdateClientInformation = (values: any) => {
    this.props.showLoader();

    let url = `${configJSON.clientInformationEndpoint}`;

    let method = "POST";

    if (this.state.clientInformation?.id) {
      url = `${configJSON.clientInformationEndpoint}/${this.state.clientInformation.id}`;
      method = "PATCH";
    }

    const httpBody = {
      client_informations: {
        first_name: values?.firstName?.trimEnd(),
        last_name: values?.lastName?.trimEnd(),
        firm_name: values?.firmName?.trimEnd(),
        spouse_first_name: values?.spouseFirstName?.trimEnd(),
        spouse_last_name: values?.spouseLastName?.trimEnd(),
        primary_email: values?.primaryEmail?.trimEnd(),
        secondary_email: values?.secondaryEmail?.trimEnd(),
        primary_phone: values?.primaryPhone?.trimEnd(),
        secondary_phone: values?.secondaryPhone?.trimEnd(),
        notes: values?.notes,
        profile_status: "Prospect",
        client_discovery: {
          inputField1: values?.input1,
          inputField2: values?.input2,
          inputField3: values?.input3,
          inputField4: {
            firstPriority: values?.firstPriority,
            secondPriority: values?.secondPriority,
            thirdPriority: values?.thirdPriority,
          },
          inputField5: values?.input5,
          isClientAligned: this.state.isClientAligned,
          scheduleDateTime: this.state.scheduleDateTime,
        },
        road_customization_id: +this.state.roadMapInfo.id,
      },
    };

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAndUpdateClientInformationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchClient = (values: any) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchClientApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/search_client?first_name=${values.firstName}&last_name=${values.lastName}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deletePaymentTermApi = (paymentTerm: any, event) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };
    let url = `bx_block_custom_packages/destroy_payment_terms/${paymentTerm.id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePaymentTermCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    const data = {
      payment_term: {
        [paymentTerm.attributes.selectedPaymentTerm.paymentTermKey]: false,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  orderOfPackage = (values: any) => {
    this.props.showLoader();
    const abcd = (item, index) => {
      const selectedTerm = item.attributes.selectedPaymentTerm.simpleValue;

      const updatedAttributes = { ...item.attributes };

      if (selectedTerm === "(quarterly)") {
        updatedAttributes.payment_term.quarterly_position =
          item.attributes.selectedPaymentTerm.order_number;
      } else if (selectedTerm === "(monthly)") {
        updatedAttributes.payment_term.monthly_position =
          item.attributes.selectedPaymentTerm.order_number;
      } else if (selectedTerm === "(yearly)") {
        updatedAttributes.payment_term.yearly_position =
          item.attributes.selectedPaymentTerm.order_number;
      }
      item.attributes = updatedAttributes;

      return item;
    };

    const updatedData = values.map(abcd);
    const idMap = new Map();

    updatedData.forEach((item) => {
      const id = item.id;
      const attributes = item.attributes;

      if (idMap.has(id)) {
        const existingItem = idMap.get(id);
        if (attributes.payment_term.is_monthly_selected) {
          existingItem.monthly_position =
            attributes.payment_term.monthly_position;
        }
        if (attributes.payment_term.is_quarterly_selected) {
          existingItem.quarterly_position =
            attributes.payment_term.quarterly_position;
        }
        if (attributes.payment_term.is_yearly_selected) {
          existingItem.yearly_position =
            attributes.payment_term.yearly_position;
        }
      } else {
        idMap.set(id, {
          id,
          monthly_position: attributes.payment_term.is_monthly_selected
            ? attributes.payment_term.monthly_position
            : null,
          quarterly_position: attributes.payment_term.is_quarterly_selected
            ? attributes.payment_term.quarterly_position
            : null,
          yearly_position: attributes.payment_term.is_yearly_selected
            ? attributes.payment_term.yearly_position
            : null,
        });
      }
    });

    const updatedArray = Array.from(idMap.values());
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };
    let url = `${configJSON.updateOfPackage}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.orderOfPackageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    const httpBody = {
      data: {
        package_positions: updatedArray,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  submitQuiz = () => {
    this.props.showLoader();

    let url = `bx_block_pricingcalculator/road_map_quizzes`;

    let method = "POST";

    if (this.state.quizId) {
      url = `bx_block_pricingcalculator/road_map_quizzes/${this.state.quizId}`;
      method = "PATCH";
    }

    const httpBody = {
      quiz: {
        road_customization_id: this.state.roadMapInfo?.id,
        quiz: this.getQuizObject(),
      },
    };

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitQuizApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCustomPackage = () => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomPackageAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_packages/custom_package_creation`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateRoadMap = async (values: any) => {
    this.props.showLoader();

    let accountId = await StorageProvider.get("accountId");

    const headers = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateRoadmapApiCallId = requestMessage.messageId;

    let formData = new FormData();

    formData.append("account_id", accountId);
    formData.append("add_primary_color", this.state.primaryColor);
    formData.append("add_secondary_color", this.state.secondaryColor);
    formData.append("email", values.email);
    formData.append("firm_name", values.firm_name);
    formData.append("is_default", false);
    formData.append(
      "phone_number",
      (values.phone_number || "").replace(/ /g, "")
    );
    formData.append("web_url", values.web_url);

    if (this.state.logo?.file) {
      formData.append("add_logo", this.state.logo?.file);
    } else if (this.state.logoAsFile) {
      formData.append("add_logo", this.state.logoAsFile);
    }
    formData.append("confidential_statement", values.confidential_statement);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.roadmapEndpoint}/${this.state.roadMapInfo?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBranding = () => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBrandingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_theme/themes/${this.state.brandingId || 1}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createAndUpdateBranding = (values: any) => {
    this.props.showLoader();

    let url = "bx_block_theme/themes";
    let method = "POST";

    if (this.state.brandingId) {
      url = `bx_block_theme/themes/${this.state.brandingId}`;
      method = "PUT";
    }

    const headers = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAndUpdateBrandingApiCallId = requestMessage.messageId;

    let formData = new FormData();
    formData.append("theme[primary_color]", this.state.primaryColor);
    formData.append("theme[secondary_color]", this.state.secondaryColor);
    if (this.state.logo?.file) {
      formData.append("theme[logo]", this.state.logo?.file);
    } else if (this.state.logoAsFile) {
      formData.append("theme[logo]", this.state.logoAsFile);
    }
    formData.append("theme[firm_name]", values?.firm_name);
    formData.append("theme[web_url]", values?.web_url);
    formData.append("theme[email]", values?.email);
    formData.append("theme[phone_number]", values?.phone_number);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createFirmFinancials = (values: any, isDefault: boolean) => {
    this.props.showLoader();

    let url = "bx_block_pricingcalculator/firm_finances";
    let method = "POST";

    if (this.state.firmFinanceId) {
      method = "PATCH";
      url = `bx_block_pricingcalculator/firm_finances/${this.state.firmFinanceId}`;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createFirmFinancialsApiCallId = requestMessage.messageId;

    const laborsAttributes = values.expenseLaborsAttributes.map(
      (labor, index) => {
        const { labor_role, employee_compensation, unit, _destroy, id } = labor;
        let obj = {
          labor_role: labor_role || `Additional Labor ${index - 2}`,
          current_annual:
            +("" + employee_compensation)?.replace(/,/g, "") *
              (+("" + unit)?.replace(/,/g, "") || 0) || 0,
          unit: +("" + unit)?.replace(/,/g, "") || 0,
          employee_compensation:
            +("" + employee_compensation)?.replace(/,/g, "") || 0,
          _destroy: _destroy,
          id: this.state.firmFinanceId && !labor.additionalLaborEntry && id,
        };
        return obj;
      }
    );

    const packages = configJSON.packageList;

    const pricing_values_attributes = packages.map(
      ({
        packageName,
        packageType,
        revGoalPercent,
        percentOfClient,
        timePeriod,
        id,
      }) => {
        return {
          id: this.state.firmFinanceId
            ? this.state.pricingIdObject[id]
            : undefined,
          package_name: packageName,
          package_type: packageType,
          time_period: timePeriod,
          no_of_packages: 0,
          rev_goal_percent: +this.state[revGoalPercent],
          percent_of_client: +this.state[percentOfClient],
          strategic_price: 0,
          revenue_map: 0,
        };
      }
    );

    const httpBody = {
      firm_finance: {
        default: isDefault || undefined,
        firm_labor_expenses_attributes: [...laborsAttributes],
        road_customization_id: +this.state.roadMapInfo?.id,
        gross_revenue_current_annual: +(
          "" + this.state.currentAnnualGross
        )?.replace(/,/g, ""),
        gross_revenue_goal_annual: +("" + this.state.goalAnnualGross)?.replace(
          /,/g,
          ""
        ),
        owner_compensation_current_annual: +(
          this.state.currentAnnualOwner + ""
        )?.replace(/,/g, ""),
        owner_compensation_goal_annual: +(
          this.state.goalAnnualOwner + ""
        )?.replace(/,/g, ""),
        annual_free_cashflow: 0,
        monthly_revenue_floor: 0,
        gross_profit_margin: 0,

        operating_space_expense: +(
          values.expenses.operatingSpaceExpense + ""
        )?.replace(/,/g, ""),
        technology_expense: +(values.expenses.technologyExpense + "")?.replace(
          /,/g,
          ""
        ),
        bizdev_marketing_expense: +(
          values.expenses.bizdevMarketingExpense + ""
        )?.replace(/,/g, ""),
        insurance_expense: +(values.expenses.insuranceExpense + "")?.replace(
          /,/g,
          ""
        ),
        others_expense: +(values.expenses.othersExpense + "")?.replace(
          /,/g,
          ""
        ),
        // can be 0
        labor_total_expense: +(values.laborTotalExpense + "")?.replace(
          /,/g,
          ""
        ),
        total_expenses: +(values.totalExpenses + "")?.replace(/,/g, ""),
        business_client_info: +(this.state.businessClients + "")?.replace(
          /,/g,
          ""
        ),
        individual_client_info: +(this.state.individualClients + "")?.replace(
          /,/g,
          ""
        ),
        total_client_info:
          +(this.state.businessClients + "")?.replace(/,/g, "") +
          +(this.state.individualClients + "")?.replace(/,/g, ""),
        business_revenue: 0,
        individual_revenue: 0,
        total_revenue: 0,
        total_arr: 0,
        total_mrr: 0,
        pricing_values_attributes: [...pricing_values_attributes],
      },
    };

    if (this.state.firmFinanceId) {
      httpBody.firm_finance.id = this.state.firmFinanceId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDefaultFirmFinancialsByAccount = async () => {
    let accountId = await StorageProvider.get("accountId");
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDefaultFirmFinancialsByAccountAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${accountId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showCorrectImage = () => {
    const { classes } = this.props;
    if (this.state.logo?.base64) {
      return (
        <div style={{ position: "relative", width: "50%" }}>
          <CloseIcon
            onClick={this.removeLogo}
            className={classes.crossOverImage}
          />
          <img
            src={this.state.logo?.base64}
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "6px",
            }}
          />
        </div>
      );
    } else if (this.state.logo) {
      return (
        <div style={{ position: "relative", width: "50%" }}>
          <CloseIcon
            onClick={this.removeLogo}
            className={classes.crossOverImage}
          />
          <img
            src={this.state.logo}
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "6px",
            }}
          />
        </div>
      );
    } else {
      return (
        <UploadBox
          multiple={false}
          acceptedFormat={"image/*"}
          onTouched={() => {}}
          onSelectFile={(files: any) => {
            this.handleLogoImage(files);
          }}
          showToast={this.props.showToast.bind(this)}
        />
      );
    }
  };

  handleColorInputChange = (e) => {
    let event = hexMask(e);
    this.setState({
      [e.target.name]: event.target.value,
    });
  };

  handlePrimaryColorChange = (color) => {
    this.setState({
      primaryColor: color,
    });
  };

  handleSecondaryColorChange = (color) => {
    this.setState({
      secondaryColor: color,
    });
  };

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          base64: reader.result,
          file: file,
          id: new Date().getTime(),
        });
      reader.onerror = (error) => reject(error);
    });
  };

  handleLogoImage = async (files: FileList) => {
    const filePathsPromises = [];
    [...files].forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      logo: filePaths[0],
    });
  };

  removeLogo = () => {
    this.setState({
      logo: null,
      logoAsFile: null,
    });
  };
  getGSuiteData = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGSuiteDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gsuiteintegration/gsuites`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  addCustomFeatureInDb2  = (data: any) => {
    if (data) {
      const headers = {
        token: this.state.token,
        "content-type": "application/json",
      };

      const dataset2 = {
        "package_feature": {
          "tag": data.featureTag,
          "payment_frequency": data.featureFrequency,
          "payment_period": data.priceDetails,
          "default_cost": data.defaultFeatureCost,
          "feature_name": data.featureName,
          "package_category_id": data.featureCategory,
          "bump_language": "language"
        }
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );


      this.addCustomFeatureInDbCallId2 = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_custom_packages/create_package_feature`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(dataset2)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  }
}
