//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  checklist: object;
  selectedSection: object;
  isMoveToSectionTooltipOpen: any;
  tooltipObj: object;
  sectionAccordion: boolean;
  isCollapseExpanded: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CreateOnboardingChecklistController extends BlockComponent<Props, S, SS> {
  getChecklistApiCallId: string = "";
  updateChecklistApiCallId: string = "";
  moveQuestionToSectionApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      checklist: {},
      selectedSection: {},
      isMoveToSectionTooltipOpen: false,
      tooltipObj: {},
      sectionAccordion: true,
      isCollapseExpanded: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleEditQuestion = (sectionItem, questionItem, setFieldValue, values) => {

    setFieldValue("newQuestion", {
      sectionName: sectionItem.attributes.section_name,
      questionName: questionItem.attributes.question_name,
      requestDocument: questionItem.attributes.request_document,
      questionType: questionItem.attributes.question_type,
      questionOptions: questionItem.attributes.question_options || [],
    });
    
    setFieldValue("editingQuestion", questionItem);    
  };

  handleAddQuestion = (values, setFieldValue, updateChecklist) => {
    console.log("updateChecklist",updateChecklist);
    
    const isEditing = values.editingQuestion && values.editingQuestion.id;
    
    const newChecklist = this.createUpdatedChecklist(values, isEditing);
    
    updateChecklist(newChecklist);
    this.resetFormValues(setFieldValue);
  };
  
  
  createUpdatedChecklist = (values, isEditing) => {
    return {
      ...values.checklist,
      attributes: {
        ...values.checklist.attributes,
        sections: {
          data: values.checklist.attributes.sections.data.map((sectionItem) => {
            if (sectionItem.attributes.section_name !== values.newQuestion.sectionName) {
              return sectionItem;
            }
            
            return this.updateSectionWithQuestion(sectionItem, values, isEditing);
          }),
        },
      },
    };
  };
  
  updateSectionWithQuestion = (sectionItem, values, isEditing) => {
    if (isEditing) {
      return this.updateExistingQuestion(sectionItem, values);
    } else {
      return this.addNewQuestion(sectionItem, values);
    }
  };
  
  updateExistingQuestion = (sectionItem, values) => {
    return {
      ...sectionItem,
      attributes: {
        ...sectionItem.attributes,
        questions: {
          data: sectionItem.attributes.questions.data.map((questionItem) => {
            if (questionItem.id !== values.editingQuestion.id) {
              return questionItem;
            }
            
            return {
              ...questionItem,
              attributes: {
                ...questionItem.attributes,
                question_name: values.newQuestion.questionName,
                request_document: values.newQuestion.requestDocument,
                question_type: values.newQuestion.questionType,
                question_options: values.newQuestion.questionOptions,
              },
            };
          }),
        },
      },
    };
  };
  
  addNewQuestion = (sectionItem, values) => {
    const newQuestion = {
      id: "",
      isAdditionalQuestion: true,
      type: "question",
      attributes: {
        id: "",
        question_name: values.newQuestion.questionName,
        checked: true,
        client_status: "Client",
        request_document: values.newQuestion.requestDocument,
        status: "Requested/Submitted",
        current_gathered_details: "",
        question_rank: sectionItem.attributes.questions.data.length,
        question_options: values.newQuestion.questionOptions,
        question_type: values.newQuestion.questionType,
        document: null,
      },
    };
    
    return {
      ...sectionItem,
      attributes: {
        ...sectionItem.attributes,
        questions: {
          data: [...sectionItem.attributes.questions.data, newQuestion],
        },
      },
    };
  };
  
  resetFormValues = (setFieldValue) => {
    setFieldValue("newQuestion", {
      sectionName: "Select",
      questionName: "",
      requestDocument: false,
      questionType: "",
      questionOptions: [],
    });
    
    setFieldValue("editingQuestion", null);
  };

  handleChecklistResponse = (responseJson, showToastMessage = null) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      let tooltipObj = {};

      const checklist = {
        attributes: {
          checklist_name: responseJson.data.attributes.checklist_name,
          id: responseJson.data.attributes.id,
          sections: {
            data: responseJson.data.attributes.sections.data?.map((sectionItem, indexSectionItem) => {
              return {
                attributes: {
                  id: sectionItem.attributes.id,
                  questions: {
                    data: sectionItem.attributes.questions?.data?.map((questionItem, indexQuestionItem) => {
                      tooltipObj[`${sectionItem.id}-${questionItem.id}`] = false;
                      return {
                        attributes: {
                          checked: questionItem.attributes.checked,
                          client_status: questionItem.attributes.client_status,
                          current_gathered_details: questionItem.attributes.current_gathered_details,
                          document: questionItem.attributes.document,
                          id: questionItem.attributes.id,
                          question_name: questionItem.attributes.question_name,
                          question_rank: indexQuestionItem,
                          request_document: questionItem.attributes.request_document,
                          status: questionItem.attributes.status,
                          question_options: questionItem?.attributes?.question_options,
                          question_type: questionItem?.attributes?.question_type,
                        },
                        id: questionItem.id,
                        type: questionItem.type,
                      };
                    }),
                  },
                  section_name: sectionItem.attributes.section_name,
                  section_rank: indexSectionItem,
                },
                id: sectionItem.id,
                type: sectionItem.type,
              };
            }),
          },
        },
        id: responseJson.data.id,
        type: responseJson.data.type,
      };
  
      this.setState({
        checklist: checklist,
        tooltipObj: tooltipObj,
      });
  
      if (showToastMessage) {
        this.props.showToast({ type: "success", message: showToastMessage });
      }
    } else if (responseJson?.message) {
      this.props.showToast({
        type: "error",
        message: responseJson?.message,
      });
    } else if(responseJson?.errors) {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else if (typeof responseJson?.errors === "object") {
        this.props.showToast({
          type: "error",
          message: Object.values(responseJson.errors)[0],
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getChecklistApiCallId) {
        this.handleChecklistResponse(responseJson);
      } else if (apiRequestCallId === this.updateChecklistApiCallId) {
        this.handleChecklistResponse(responseJson, "Checklist updated successfully.");
      } else if (apiRequestCallId === this.moveQuestionToSectionApiCallId) {
        this.handleChecklistResponse(responseJson, "Checklist updated successfully.");
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      const msg = await this.props.showAlert({ title: "Error", message: AlertBodyMessage });
      if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }

  getChecklist = (checklistId) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getChecklistApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_checklist/checklists/${checklistId}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateChecklist = (newChecklist, questionToDelete, sectionToDelete) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateChecklistApiCallId = requestMessage.messageId;

    const httpBody = {
      checklists: {
        id: newChecklist.attributes.id,
        account_id: newChecklist.attributes.account_id,
        checklist_name: newChecklist.attributes.checklist_name,
        sections_attributes: newChecklist.attributes.sections?.data?.map((sectionItem, indexSectionItem) => {
          let tempSection = {
            section_name: sectionItem.attributes.section_name,
            section_rank: indexSectionItem,
            id: sectionItem.attributes.id,
            questions_attributes: sectionItem.attributes.questions?.data?.map((questionItem, indexQuestionItem) => {
              let tempQuestion = {
                checked: questionItem.attributes.checked,
                client_status: questionItem.attributes.client_status,
                current_gathered_details: questionItem.attributes.current_gathered_details,
                document: questionItem.attributes.document,
                id: questionItem.attributes.id,
                question_name: questionItem.attributes.question_name,
                question_rank: indexQuestionItem,
                request_document: questionItem.attributes.request_document,
                status: questionItem.attributes.status,
                question_options: questionItem?.attributes?.question_options,
                question_type: questionItem?.attributes?.question_type,
              };
              if (questionItem.isAdditionalQuestion) {
                delete tempQuestion.id;
              }
              if (questionItem.id === questionToDelete?.id) {
                tempQuestion._destroy = true;
              }
              return tempQuestion;
            }),
          };
          if (sectionItem.isAdditionalSection) {
            delete tempSection.id;
          }
          if (sectionItem.id === sectionToDelete?.id) {
            tempSection._destroy = true;
          }
          return tempSection;
        }),
      },
    };

    let url = `bx_block_checklist/checklists/${+newChecklist.id}`;
    let type = "PUT";

    if (httpBody.checklists.id === undefined) {
      delete httpBody.checklists.id;
      url = `bx_block_checklist/checklists`;
      type = "POST";
    }

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  moveQuestionToSection = (values) => {
    const { checklist, move } = values;
    const { fromSection, toSection, questionToMove } = move;

    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.moveQuestionToSectionApiCallId = requestMessage.messageId;

    const updatedSections = checklist.attributes.sections?.data.map((sectionItem, indexSectionItem) => {
      const isFromSection = sectionItem.id === fromSection.id;
      const isToSection = sectionItem.id === toSection.id;

      if (isToSection) {
        sectionItem = {
          ...sectionItem,
          attributes: {
            ...sectionItem.attributes,
            questions: {
              data: [...(sectionItem.attributes.questions.data ?? []), questionToMove],
            },
          },
        };
      }

      const updatedQuestions = sectionItem.attributes.questions?.data.map((questionItem, indexQuestionItem) => {
        const isQuestionToMove = questionItem.id === questionToMove.id;

        const tempQuestion = {
          checked: questionItem?.attributes?.checked,
          client_status: questionItem?.attributes?.client_status,
          current_gathered_details: questionItem?.attributes?.current_gathered_details,
          document: questionItem?.attributes?.document,
          id: questionItem?.attributes?.id,
          question_name: questionItem?.attributes?.question_name,
          question_rank: indexQuestionItem,
          request_document: questionItem?.attributes?.request_document,
          status: questionItem?.attributes?.status,
          question_options: questionItem?.attributes?.question_options,
          question_type: questionItem?.attributes?.question_type,
        };

        if (questionItem.isAdditionalQuestion || (isToSection && isQuestionToMove)) {
          delete tempQuestion.id;
        }

        if (isFromSection && isQuestionToMove) {
          tempQuestion._destroy = true;
        }

        return tempQuestion;
      });

      const tempSection = {
        section_name: sectionItem.attributes.section_name,
        section_rank: indexSectionItem,
        id: sectionItem.attributes.id,
        questions_attributes: updatedQuestions,
      };

      if (sectionItem.isAdditionalSection) {
        delete tempSection.id;
      }

      return tempSection;
    });

    const updatedChecklist = {
      checklist_name: checklist.attributes.checklist_name,
      sections_attributes: updatedSections,
    };

    const httpBody = { checklists: updatedChecklist };

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_checklist/checklists/${+checklist.id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
