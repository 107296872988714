import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps,RouteComponentProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";

export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  RouteComponentProps &
  withLoaderProps & {
    id: string;
    classes : any
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  profile: any;
  uploadedImage: null | string;
  client : any
  clientId :null,
  expandedFolders: any
  isUploadDialogOpen: boolean;
  category: string;
  file:File | null;
  folderList: any;
  clientToken: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClientDetailsContainerController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateProfileApiCallId: string = "";
  getSingleClientApiCallId: string = "";
  getclientDocumentDataCallId: string = "";
  getFolderListApiCallId: string = "";
  getfolderListShownApiCallId: string ="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      profile: {},
      uploadedImage: null,
      client : null,
      clientId : null,
      expandedFolders: {},
      isUploadDialogOpen: false,
      category:"",
      file:null,
      folderList:[],
      clientToken:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

     this.handleReceive(apiRequestCallId , responseJson)
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (AlertBodyMessage === 'Session TimeOut,Please Login Again.' || AlertBodyMessage === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }

  handleReceive = (apiRequestCallId  :any, responseJson : any) => {
    if (apiRequestCallId === this.getSingleClientApiCallId) {
      console.log("responseJson?.data?.attributes.client_token",responseJson?.data?.attributes.client_token);
      
      this.props.hideLoader();
      
      if (responseJson?.data?.attributes) {
        this.setState({
          client: responseJson?.data?.attributes,
          clientToken: responseJson?.data?.attributes.client_token
        });
      } else {
        this.handleApiError(responseJson)
      }
    }
    if (apiRequestCallId === this.getFolderListApiCallId){      
      this.setState({folderList:responseJson})
    }
    if (apiRequestCallId === this.getFolderListShownClient){
      this.handleFolderList(responseJson)
    }
    if (apiRequestCallId === this.getclientDocumentDataCallId) { 
      console.log("responseJson",responseJson);
      this.props.hideLoader();
      if (responseJson.length > 0) {
        this.props.showToast({
          type: "success",
          message: "Document uploaded successfully"
        });
      } else {
        this.handleApiError(responseJson);
      }
    }
  }
  handleApiError = (responseJson:any) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

    openUploadDialog = () => {
      this.setState({ isUploadDialogOpen: true });
    };
  
    closeUploadDialog = () => {
      this.setState({ isUploadDialogOpen: false });
    };

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        this.setState({file: event.target.files[0]});
      }
    };

    documentCategories = [
      'Tax Returns',
      'Strategy Documents', 
      'Submitted Documents', 
      'Accounting Documents', 
      'Payroll Documents'
    ];

    handleFolderList = (responseJson:any) => {
      console.log("responseJson",responseJson);
      
    }

    handleDocumentUpload = () => {
      const { file, category } = this.state;
      if (file && category) {
        try {
          const matchingFolder = this.state.folderList.find(
            (folder: any) => folder.folder_name === category
          );          
          if (matchingFolder) {
            this.uploadDocument(file, matchingFolder.id);
            this.setState({
              isUploadDialogOpen: false, 
              file: null,
              category: ''
            });
          } else {
            this.props.showToast({
              type: "error",
              message: "No matching folder found for the selected category."
            });
          }
        } catch (error) {
          this.handleApiError(error);
        }
      } else {
        this.props.showToast({
          type: "error",
          message: "Please select a file and category to upload."
        });
      }
    };

    uploadDocument = (file: File, folderId: number)=> {
      this.props.showLoader();
      
      const formData = new FormData();
      formData.append('client_documents[document_folder_id]', folderId.toString())
      formData.append('client_documents[client_file]', file, file.name);
  
      const headers = {
        token: this.state.token,  
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getclientDocumentDataCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_client_documents/client_documents`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getFolderListClient = () => {
      this.props.showLoader();
  
      const headers = {
        "content-type": "application/json",
        token: this.state.token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getFolderListApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_client_documents/folder_list`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getFolderListShownClient = () => {
      this.props.showLoader();
  
      const headers = {
        token: this.state.clientToken,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getfolderListShownApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_client_documents/client_documents`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  
  getSingleClient = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleClientApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/${this.state.clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
