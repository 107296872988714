//@ts-nocheck
import React from "react";
import ClientDocumentUploadController from "./clientDocumentUploadController.web";
import { Button, Grid, withStyles, createStyles, Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, TextField, DialogActions } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ViewDocumentDialogWeb from "../Settings/ViewDocumentDialog.web";
export class ClientDocumentUpload extends ClientDocumentUploadController {
  pdfRef = React.createRef<any>();


  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    const clientId = await StorageProvider.get("clientId");
    this.setState(
      {
        token: token,
        accountId: accountId,
        clientId: clientId,
      },
      () => {
        this.getSingleClient();
        this.getFolderListClient();
        this.getFolderListShownClient();
      }
    );
  }
  handleFileSelection = async (files: FileList) => {
    const filePathsPromises: any = [];
    [...files].forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      uploadedImage: filePaths[0] as string,
    });
  };
  toggleFolder = (folderName: string) => {
    this.setState((prevState) => ({
      expandedFolders: {
        ...prevState.expandedFolders,
        [folderName]: !prevState.expandedFolders[folderName],
      },
    }));
  };

  OpenSignedDocument = (element) => {
    this.props.openDialogBox({
      dataToPass: {
        documentItem:element,
        btnshow: true,
        view: true,
        SignDetails: element
      },
      width: "100%",
      renderedComponent: ViewDocumentDialogWeb,
      withCustomDialog: false,
    })
  };

  renderFolderRow = (folderName: string, date: string, size: string, children?: any) => {
    const isExpanded = this.state.expandedFolders[folderName];
    return (
      <React.Fragment key={folderName}>
        <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
          <CustomGrid
            item
            width="50%"
            className={this.props.classes.bodyRowStyles}
            container
            id="homebton"
            alignItems="center"
            onClick={() => this.toggleFolder(folderName)}
            style={{ cursor: "pointer" }}
          >
            {isExpanded ? (
              <ArrowDropDownIcon size="large" className={this.props.classes.iconMarginRight} />
            ) : (
              <ArrowRightIcon size="large" className={this.props.classes.iconMarginRight} />
            )}
            <FolderOpenIcon color="primary" className={this.props.classes.iconMarginRight} />
            {folderName}
          </CustomGrid>
          <CustomGrid item width="25%" className={this.props.classes.bodyRowStyles}>
            {date}
          </CustomGrid>
          <CustomGrid
            item
            width="25%"
            className={[this.props.classes.bodyRowStyles, this.props.classes.lastColumn]}
          >
            {size}
          </CustomGrid>
        </CustomGrid>
        {isExpanded && children && (
          <CustomGrid item xs={12} style={{ paddingLeft: "2rem" }}>
            {children}
          </CustomGrid>
        )}
      </React.Fragment>
    );
  };

  DocumentUploadDialog = () => {
    const { isUploadDialogOpen, category, file } = this.state;
    const { classes } = this.props;

    return (
      <Dialog 
        open={isUploadDialogOpen} 
        onClose={this.closeUploadDialog} 
        maxWidth="sm" 
        fullWidth
      >
        <DialogTitle className={classes.headingSize}>Upload Document</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel >Document Category</InputLabel>
            <Select
            data-test-id="selectCategory"
              value={category || ''}
              onChange={(e) => this.setState({category: e.target.value})}
            >
              {this.documentCategories.map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <input
            accept=".pdf,.doc,.docx"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple={false}
            type="file"
            onChange={this.handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button 
              variant="contained" 
              component="span" 
              color="primary" 
              fullWidth 
              style={{ marginTop: '1rem' }}
            >
              Select File
            </Button>
          </label>
          {file && (
            <TextField
              margin="normal"
              fullWidth
              label="Selected File"
              value={file.name}
              InputProps={{
                readOnly: true,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button data-test-id="closeUploadDialog" onClick={this.closeUploadDialog} color="secondary">
            Cancel
          </Button>
          <Button 
            onClick={this.handleDocumentUpload} 
            color="primary" 
            disabled={!file && !category}
            data-test-id="handleDocumentUpload"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const documentTemplates = this.state.client?.document_templates || [];
    const { classes } = this.props;
    return (
      <Grid container>
        {this.DocumentUploadDialog()}
        <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
          <CustomTypography color="primary" fontSize="1.3rem" fontWeight="bold">
            Documents
          </CustomTypography>
          <Button data-test-id="openUploadDialog" color="primary" variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={this.openUploadDialog}>
            Upload Documents
          </Button>
        </CustomGrid>

        <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center" className={classes.tableContainerStyles}>

          <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
            <CustomGrid item width="50%" className={classes.headingRowStyles}>
              Name
            </CustomGrid>
            <CustomGrid item width="25%" className={[classes.headingRowStyles, classes.headingMiddleColumn]}>
              Date Modified
            </CustomGrid>
            <CustomGrid item width="25%" className={[classes.headingRowStyles, classes.lastColumn]}>
              Size
            </CustomGrid>
          </CustomGrid>
          {this.renderFolderRow(
            "Tax Returns",
            "March 17, 2023 at 12:30 PM",
            "9.2MB",
            <div>
              <CustomTypography>No Documents are available</CustomTypography>
            </div>
          )}
          {this.renderFolderRow("Strategy Documents", "March 17, 2023 at 12:30 PM", "9.2MB", <div>
            <CustomTypography>No Documents are available</CustomTypography>
          </div>)}
          {this.renderFolderRow("Submitted Documents", "March 17, 2023 at 12:30 PM", "9.2MB", <div>
            <CustomTypography>No Documents are available</CustomTypography>
          </div>)}
          {this.renderFolderRow("Accounting Documents", "March 17, 2023 at 12:30 PM", "9.2MB", <div>
            <CustomTypography>No Documents are available</CustomTypography>
          </div>)}
          {this.renderFolderRow("Payroll Documents", "March 17, 2023 at 12:30 PM", "9.2MB", <div>
            <CustomTypography>No Documents are available</CustomTypography>
          </div>)}
          {this.renderFolderRow("Miscellaneous", "", "", documentTemplates
            .filter((element) => element.status)
            .map((element: { id: string; title: string }) => (
              <CustomGrid
                key={element.id}
                style={{ marginBottom: "0.6rem" }}
                item xs={12} container justifyContent="space-between" alignItems="center"
                id="opendoc"
                onClick={this.OpenSignedDocument.bind(this, element)}
              >
                <CustomGrid
                  item
                  width="50%"
                  className={this.props.classes.bodyRowStyles}
                  container
                  alignItems="center"
                  style={{ cursor: "pointer" }}
                >
                  <PictureAsPdfIcon color="secondary" className={classes.iconMarginRight} />
                  <CustomTypography>{element.title}</CustomTypography>  </CustomGrid>
                <CustomGrid item width="25%" className={this.props.classes.bodyRowStyles}>
                  {new Date(element.signing_time).toLocaleString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                  }).replace(/(\d{1,2},)(.*?),/, "$1$2 at")}
                </CustomGrid>
                <CustomGrid
                  item
                  width="25%"
                  className={[this.props.classes.bodyRowStyles, this.props.classes.lastColumn]}
                >
                  {element.file_size}
                </CustomGrid>
              </CustomGrid>
            )))}
        </CustomGrid>
      </Grid>
    );
  }
}

const ClientDocumentUploadWithRouter = withRouter(ClientDocumentUpload);
const ClientDocumentUploadAlertBox = withAlertBox(ClientDocumentUploadWithRouter);
const ClientDocumentUploadLoader = withLoader(ClientDocumentUploadAlertBox);
const ClientDocumentUploadToast = withToast(ClientDocumentUploadLoader);
const ClientDocumentUploadWithDialog = withDialog(ClientDocumentUploadToast);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(4),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      crossOverImage: {
        right: "-5px",
        top: "-5px",
        position: "absolute",
        backgroundColor: "red",
        color: "white",
        padding: "3px",
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        cursor: "pointer",
      },
      headingSize: {
        '& .MuiTypography-h6-233': {
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color:"#54A4A6"
        }
      },
      tableContainerStyles: {
        backgroundColor: "#fff",
        borderRadius: "1rem",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        margin: "1rem 0"
      },
      headingRowStyles: {
        backgroundColor: "#FCFCFC",
        padding: "1rem",
        fontSize: "1.1rem",
        fontWeight: 600,
        color: theme.palette.text.secondary
      },
      bodyRowStyles: {
        backgroundColor: "#fff",
        padding: "1rem",
        fontSize: "1.1rem",
        fontWeight: 500,
        color: theme.palette.text.secondary
      },
      iconMarginRight: {
        marginRight: "0.5rem"
      },
      lastColumn: {
        textAlign: "right",
        paddingRight: "1.5rem"
      },
      headingMiddleColumn: {
        borderLeft: `1px solid ${theme.palette.text.secondary}`,
        borderRight: `1px solid ${theme.palette.text.secondary}`
      }
    }),
  { withTheme: true }
)(withConfirmBox(ClientDocumentUploadWithDialog));
